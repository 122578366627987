<template lang="pug">
VCard
  VCardSubtitle.my-4 {{ chapter.bookName }}
  VCardTitle.text-h1(
    :class="{ 'text-primary': $vuetify.theme.name !== 'dark' }"
  ) {{ chapter.number }}
  VCardSubtitle.mb-4 {{ i18n.t("ScriptureIndex.comments", { count: index.count }) }}
  VCardActions
    VBtn(
      :title="`Read ${chapter.bookName} ${chapter.number}`"
      :to="localePath(getBibleLocationFromRange(chapter))"
      :color="$vuetify.theme.name === 'dark' ? 'primary-c050' : 'primary-c600'"
      variant="outlined"
    ) {{ i18n.t("Global.read") }}
    IndexStudyMenu(
      :commentary-type-counts="index.commentaryTypes"
      :range="chapter"
    )
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { StandaloneChapterData } from "@rsc/scripture-model";
import type { CommentaryIndexRangeCount } from "~/units/commentary/commentaryIndex";
import { getBibleLocationFromRange } from "~/routing/locations";

const i18n = useI18n();
const localePath = useLocalePath();

interface Props {
  index: CommentaryIndexRangeCount<StandaloneChapterData>;
}

const props = withDefaults(defineProps<Props>(), {});

const chapter = computed(() => props.index.range);
</script>
